import { WebPubSubClient } from "@azure/web-pubsub-client";
import store from '../store';
import mitt from "mitt"; // 컴포넌트간 통신

const emitter = mitt();

class WebPubSubService {
    constructor() {
        this.client = null;
    }

    connect(token) {
        if (!this.client) {
            this.client = new WebPubSubClient(token);
            
            this.client.on("server-message", (e) => {
                //console.log("message received: ", e.message.data);
                emitter.emit("alarmMsg", e.message.data);
                // Handle the received message
            });
            
            this.client.on("connected", () => {
                //console.log("connected");
            });
            
            this.client.on("disconnected", () => {
                //console.log("disconnected");

                // 로그인 된 상태일 때만 다시 연결
                if(store.state.user.loggedIn){
                    this.client = null;

                    var hubName = store.state.user.data.loginID;
                    
                    store.dispatch('tokenStore/token', hubName)
                    .then((res) => {
                        //console.log(res.data);

                        if(res.data){
                            var token = res.data;
                            this.connect(token);
                        }
                        
                        return res;
                    })
                    .catch(({message}) => (alert(message)));
                }
            });
            
            this.client.start();
        }
    }

    disconnect() {
        if (this.client) {
            this.client.stop();
            this.client = null;
        }
    }

    isConnected() {
        return this.client && this.client._state === "Connected";
    }
}

const webPubSubService = new WebPubSubService();

export { webPubSubService, emitter }