<!-- App.vue -->
<template>
  <router-view></router-view>
</template>

<script>

export default {
name: 'silvercare',
components: {
},
created() {},
mounted(){},
methods: {},
}
</script>
