import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import VueCookies from 'vue-cookies';
import VueJwtDecode from 'vue-jwt-decode';

import { webPubSubService } from '@/API/webPubSubService';

const routes = [

    // home
    {
        path: '/',
        redirect: '/history',
        meta: {
            authRequired: true,
            title: "활동 히스토리",
        },
    },

    // 로그인
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/account/login'),
        meta: {
            title: "로그인",
        },
    },

    // 아이디 찾기
    {
        path: '/findid',
        name: 'findid',
        component: () => import('../views/account/id'),
        meta: {
            title: "아이디 찾기",
        },
    },

    // 아이디 찾기 결과
    {
        path: '/resultid/:loginid',
        name: 'resultid',
        component: () => import('../views/account/resultid_id'),
        meta: {
            title: "아이디 찾기",
        },
    },

    // 비밀번호 찾기
    {
        path: '/password',
        name: 'password',
        component: () => import('../views/account/password'),
        meta: {
            title: "비밀번호 찾기",
        },
    },

    // 비밀번호 재설정
    {
        path: '/password_reset/:loginid',
        name: 'password_reset',
        component: () => import('../views/account/password_reset'),
        meta: {
            title: "비밀번호 재설정",
        },
    },

    // 활동 히스토리
    {
        path: '/history',
        name: 'history',
        component: () => import('../views/history/index'),
        meta: {
            authRequired: true,
            title: "활동 히스토리",
        },
    },

    // 디바이스 관리
    {
        path: '/device',
        name: 'device',
        component: () => import('../views/device/index'),
        meta: {
            authRequired: true,
            title: "디바이스 관리",
        },
    },

    // 회원 및 보호 대상자 정보
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/index'),
        meta: {
            authRequired: true,
            title: "회원 및 보호 대상자 정보",
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: "active",
    //linkExactActiveClass: 'sub-active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                left: 0,
                top: 0
            };
        }
    },
});



router.beforeEach((to, from, next) => {

    /*
    * to: 이동할 url 정보가 담긴 라우터 객체
    * from: 현재 url 정보가 담긴 라우터 객체
    * next: to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
    * next() 가 호출되기 전까지 화면 전환되지 않음
    */

    var token = VueCookies.get('silvermob_accessToken');
    var isVerified = false;

    if(token !== null){
        let decoded = VueJwtDecode.decode(token)
        var expTime = decoded.exp;
        var expireDate = new Date(expTime * 1000);
        var today = new Date();
        
        if(expireDate > today){
            isVerified = true;
        }
    }

    if(to.meta.title != undefined){
        document.title = "퍼스트케어 | " + to.meta.title;
    }else{
        document.title = "퍼스트케어";
    }

    if (store.state.user.loggedIn){

        if(!webPubSubService.isConnected()){

            var hubName = store.state.user.data.loginID;
            
            store.dispatch('tokenStore/token', hubName)
            .then((res) => {
                //console.log(res.data);
    
                if(res.data){
                    var token = res.data;
                    webPubSubService.connect(token);
                }
                
                return res;
            })
            .catch(({message}) => (alert(message)));
        }
        
        return next();

    }else{
        if (token !== null){
            //accessToken이 있을 경우 진행
            var userInfo = {
                "accessToken": VueCookies.get('silvermob_accessToken'),
                "refreshToken": VueCookies.get('silvermob_refreshToken'),
                "userId": VueCookies.get('silvermob_userId'),
                "loginID": VueCookies.get('silvermob_loginID'),
                "userName": VueCookies.get('silvermob_userName'),
                "guardian": VueCookies.get('silvermob_guardian'),
            };
    
            if(isVerified){
                // accessToken 인증 시
                store.commit('setLoggedIn', true);
                store.commit('resetUserInfo', userInfo);

                if(!webPubSubService.isConnected()){

                    var hubName = store.state.user.data.loginID;
                    
                    store.dispatch('tokenStore/token', hubName)
                    .then((res) => {
                        //console.log(res.data);
            
                        if(res.data){
                            var token = res.data;
                            webPubSubService.connect(token);
                        }
                        
                        return res;
                    })
                    .catch(({message}) => (alert(message)));
                }

                return next();

            }else{

                // accessToken 만료 시 - refreshToken으로 재발급
                var refreshToken = VueCookies.get('silvermob_refreshToken');
                var userId = VueCookies.get('silvermob_userId');

                if(refreshToken != null && userId != null){
                    var params = {
                        "refreshToken": refreshToken,
                        "userId": userId,
                    };
    
                    store.dispatch('userStore/refreshToken', params)
                    .then((res) => {
                        //console.log(res.data);
                        if(res.data.success){
                        
                            // refreshToken & accessToken 재발급 후 페이지 이동
                            store.commit('refreshToken', res.data.data);

                            if(!webPubSubService.isConnected()){

                                var hubName = store.state.user.data.loginID;
                                
                                store.dispatch('tokenStore/token', hubName)
                                .then((res) => {
                                    //console.log(res.data);
                        
                                    if(res.data){
                                        var token = res.data;
                                        webPubSubService.connect(token);
                                    }
                                    
                                    return res;
                                })
                                .catch(({message}) => (alert(message)));
                            }

                            next();
    
                        }else{
                            // refreshToken 정보 안맞을 경우 로그아웃 진행
                            store.commit('logoutUser');

                            const authRequired = to.matched.some((route) => route.meta.authRequired)

                            if(!authRequired){
                                return next();
                            }else{
                                //alert('로그인이 필요한 페이지입니다!');
                                next("/login");
                            }
                        }
                        return res;
                    })
                    .catch(({message}) => (alert(message)));
                }else{

                    // refreshToken 정보 없을 경우 로그아웃 진행
                    store.commit('logoutUser');

                    const authRequired = to.matched.some((route) => route.meta.authRequired)

                    if(!authRequired){
                        return next();
                    }else{
                        //alert('로그인이 필요한 페이지입니다!');
                        next("/login");
                    }
                }

            }
            
        }else{
            
            if(!isVerified && token !== null){
                // accessToken이 종료되었을 경우 로그아웃 진행
                store.commit('logoutUser')
            }
            const authRequired = to.matched.some((route) => route.meta.authRequired)

            if(!authRequired){
                return next();
            }else{
                //alert('로그인이 필요한 페이지입니다!');
                next("/login");
            }
        }
    }

});

export default router;