import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store  from './store'
import mitt from "mitt"; // 컴포넌트간 통신

// 한글 로케일
import koLocale from '@/locales/ko';

//https://primevue.org/
import PrimeVue from 'primevue/config';

import "primevue/resources/themes/lara-light-teal/theme.css";
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';

import './assets/css/reset.css';
import './assets/css/style.css';


const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter

app.use(router).use(store).use(PrimeVue, {
    locale: koLocale // 한글 로케일 데이터 설정
  }).mount('#app');
