import axios from "axios";
import SERVER from '@/API/url'

const historyStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 활동내역
        activehistory: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Activehistory/Get/'+params.userid+'?level='+params.level+'&pagingno='+params.pagingno+'&rows='+params.rows+'&sdate='+params.sdate+'&edate='+params.edate+'&orderby='+params.orderby,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 현재상황
        getCurrent: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Activehistory/GetCurrent/'+params.userid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default historyStore