// 한글 로케일 데이터
const koLocale = {
    firstDayOfWeek: 0,
    dayNames: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
    dayNamesShort: ["일", "월", "화", "수", "목", "금", "토"],
    dayNamesMin: ["일", "월", "화", "수", "목", "금", "토"],
    monthNames: [
      "1월", "2월", "3월", "4월", "5월", "6월",
      "7월", "8월", "9월", "10월", "11월", "12월"
    ],
    monthNamesShort: [
      "1월", "2월", "3월", "4월", "5월", "6월",
      "7월", "8월", "9월", "10월", "11월", "12월"
    ],
    today: "오늘",
    clear: "초기화"
  };
  
  // Vue 컴포넌트에서 사용할 수 있도록 로케일 데이터를 내보냄
  export default koLocale;