import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url'


const userStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 로그인
        login: ({commit}, params) => {
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/User/Login', params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // refreshToken
        refreshToken: ({commit}, params) => {
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/User/refresh_token', params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 로그아웃
        logout: ({commit}) => { 
            commit('logoutUser');
            //this.$router.push('/login')
        },

        // 사용자 정보
        info: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/User/'+params.userid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 아이디 찾기
        findid: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/User/GetFindLoginid?username='+params.username+'&phone='+params.phone,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 비밀번호 찾기
        findpw: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/User/PostCertificationNumber?loginid='+params.loginid+'&phone='+params.phone, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 인증번호 확인
        checkCertiNo: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/User/GetMatchCertifino?loginid='+params.loginid+'&certifino='+params.certifino,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 비밀번호 변경
        updatePw: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.put(SERVER.URL + '/api/User/UpdatePassword', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default userStore
