import { createStore } from 'vuex';
import axios from 'axios'
import VueCookies from 'vue-cookies';
import deviceStore from '@/store/modules/deviceStore.js'
import historyStore from '@/store/modules/historyStore.js'
import statisticsStore from '@/store/modules/statisticsStore.js'
import statusStore from '@/store/modules/statusStore.js'
import tokenStore from '@/store/modules/tokenStore.js'
import userStore from '@/store/modules/userStore.js'

export default new createStore({
    state: {
        layout: 'web',
        layout_style: 'full',
        user:{
            loggedIn: false,
            data: null
        }
    },
    mutations: {
        setLoggedIn(state, value){
            state.user.loggedIn = value;
        },
        loginToken (state, data) {
            state.user.data = data;
            VueCookies.set('silvermob_accessToken', data.accessToken);
            VueCookies.set('silvermob_refreshToken', data.refreshToken);
            VueCookies.set('silvermob_userId', data.userId);
            VueCookies.set('silvermob_loginID', data.loginID);
            VueCookies.set('silvermob_userName', data.userName);
            VueCookies.set('silvermob_guardian', data.guardian);

            //console.log(state);
        },
        resetUserInfo(state, data){
            if(state.user.data == null){
                state.user.data = data;
                VueCookies.set('silvermob_accessToken', data.accessToken);
                VueCookies.set('silvermob_refreshToken', data.refreshToken);
                VueCookies.set('silvermob_userId', data.userId);
                VueCookies.set('silvermob_loginID', data.loginID);
                VueCookies.set('silvermob_userName', data.userName);
                VueCookies.set('silvermob_guardian', data.guardian);
            }
        },
        refreshToken(state, data){
            if(state.user.data == null){
                VueCookies.set('silvermob_accessToken', data.accessToken);
                VueCookies.set('silvermob_refreshToken', data.refreshToken);
            }
        },
        logoutUser (state) {
            state.user.loggedIn = false;
            state.user.data = null;
            VueCookies.remove('silvermob_accessToken');
            VueCookies.remove('silvermob_refreshToken');
            VueCookies.remove('silvermob_userId');
            VueCookies.remove('silvermob_loginID');
            VueCookies.remove('silvermob_userName');
            VueCookies.remove('silvermob_guardian');

            //console.log(state);
        },
    },
    getters: {
        user(state){
            return state.user
        }
    },
    actions: {},
    modules: {
        deviceStore: deviceStore,
        historyStore: historyStore,
        statisticsStore: statisticsStore,
        statusStore: statusStore,
        tokenStore: tokenStore,
        userStore: userStore,
    },
});
